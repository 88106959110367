import React from 'react';
import PropTypes from 'prop-types';
import ServerDOMLoader from './ServerDOMLoader';
import Loader from './Loader';

function Loaders(props) {
  if (props.isServerDOM) {
    return <ServerDOMLoader { ...props }/>;
  }
  return <Loader { ...props } />;
}

Loaders.propTypes = {
  isServerDOM: PropTypes.bool
};

export default Loaders;
