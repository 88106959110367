/* eslint-disable max-params */
/**
 * getInfo fetches assets and creates a script tag pointing the entryKey
 * in assets collection.
 *
 * NOTE: This is expressed in a way that babel will not break up getInfo
 * so we use the same method in ReactDOM and ReactServerDOM.
 *
 * In detail: async/await are transpiled in such a way functions cannot
 * be serialized by direct reference
 * @param {object} props property bucket containing the below args
 * @param {string} props.url the url that points at the desired asset bucket
 * @param {string} props.entryKey the specific key of interest in the asset bucket
 * @param {string} props.nameSpace the name of the key where globals should be placed
 * @param {string} props.replaceSrcMethodName hooks for webpack
 * @param {function} props.onAssetsLoad function delegate when assets are found
 * @param {function} props.onLoad function delegate when script tag is loaded
 * @param {function} props.onError function delegate when an error occurs
  * @returns {function} promise that can be awaited for asset discovery
 */
export function getInfo({ url, entryKey, nameSpace, replaceSrcMethodName, onAssetsLoad, onLoad, onError }) {
  window[nameSpace] = window[nameSpace] || {};

  if (!window[nameSpace].assets) {
    const options = { method: 'POST', credentials: 'include' };
    window[nameSpace].assets = fetch(url, options).then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    });
  }

  window[nameSpace].assets.then(assets => {
    if (assets && !window[nameSpace][entryKey]?.isLoaded) {
      onAssetsLoad?.(assets);
      window[nameSpace][replaceSrcMethodName] = (name) => assets.buildAssets[name];
      const body = document.getElementsByTagName('body')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = assets.entries[entryKey];
      script.async = false;
      script.onload = onLoad;
      script.onerror = onError;
      script.crossOrigin = 'anonymous';
      body.appendChild(script);
      window[nameSpace][entryKey] = { isLoaded: true };
    }
  }).catch(error => {
    onError && onError(error);
  });
  return window[nameSpace].assets;
}
