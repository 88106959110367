export const sizes = {
  padding: {
    xsmall: 1,
    small: 2,
    medium: 3,
    large: 4,
    xlarge: 5
  },
  margin: {
    xsmall: 1,
    small: 2,
    medium: 3,
    large: 4,
    xlarge: 5,
    auto: 'auto'
  }
};

export default (props = {}) => {
  const padding = props.padding  || props.p;
  const margin = props.margin  || props.m;

  return {
    /* padding */
    [`p-${sizes.padding[padding]}`]: typeof padding === 'string',
    [`pt-${sizes.padding[padding?.top]}`]: padding?.top,
    [`pr-${sizes.padding[padding?.right]}`]: padding?.right,
    [`pb-${sizes.padding[padding?.bottom]}`]: padding?.bottom,
    [`pl-${sizes.padding[padding?.left]}`]: padding?.left,
    [`px-${sizes.padding[padding?.x]}`]: padding?.x,
    [`py-${sizes.padding[padding?.y]}`]: padding?.y,

    /* margin */
    [`m-${sizes.margin[margin]}`]: typeof margin === 'string',
    [`mt-${sizes.margin[margin?.top]}`]: margin?.top,
    [`mr-${sizes.margin[margin?.right]}`]: margin?.right,
    [`mb-${sizes.margin[margin?.bottom]}`]: margin?.bottom,
    [`ml-${sizes.margin[margin?.left]}`]: margin?.left,
    [`mx-${sizes.margin[margin?.x]}`]: margin?.x,
    [`my-${sizes.margin[margin?.y]}`]: margin?.y
  };
};
