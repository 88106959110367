/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import allowedProps from './props';

const createStyled = (tag, displayName) => (...styles) => (...inlineStyleGetters) => {
  const Component = React.forwardRef((props, ref) => {
    var combinedInlineCss = {};
    inlineStyleGetters.forEach(inlineStyleGetter =>  {
      const inlineStyle = inlineStyleGetter(props);
      if (inlineStyle) combinedInlineCss = { ...combinedInlineCss, ...inlineStyle };
    });
    const combined = styles.map(style => style(props));
    let newProps = {};
    Object.keys(props).forEach(key => {
      if (props[key]) {
        const componentProps = allowedProps(key, props[key]);
        if (componentProps && Object.keys(componentProps).length) {
          newProps = Object.assign(newProps, componentProps);
        }
      }
    });

    const className = classnames(combined, props.className);
    if (className) newProps.className = className;
    if (ref) newProps.ref = ref;
    if (props.onClick) newProps.onClick = props.onClick;
    if (Object.keys(combinedInlineCss).length) newProps.style = combinedInlineCss;
    return React.createElement(tag, newProps, props.children);
  });
  Component.displayName = displayName;
  return Component;
};

export default createStyled;
