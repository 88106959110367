import { trfq } from '@px/react-utils';

const hosts = [
  'dev-godaddy.com',
  'dev-secureserver.net',
  'dev-123-reg.co.uk',
  'test-godaddy.com',
  'test-secureserver.net',
  'test-123-reg.co.uk',
  'ote-godaddy.com',
  'ote-secureserver.net',
  'godaddy.com',
  'secureserver.net',
  '123-reg.co.uk'
];

const environment = [
  'dev-',
  'test-',
  'ote-'
];

export const getHost = (hostname) => {
  const host = hosts.find(h => hostname?.includes(h));
  const env = environment.find(e => hostname?.includes(e)) || '';

  if (host) {
    return host;
  } else if (env) {
    return env + 'secureserver.net';
  }
  return 'secureserver.net';
};

export const logCustomEvent = (eid, props = {}) => {
  const customData = {
    appKey: props.appKey,
    checkout: props.checkout,
    hideCheckoutNow: props.hideCheckoutNow,
    iconOnly: props.iconOnly,
    id: props.id,
    itemCount: props.items,
    manifest: props.manifest,
    showEmpty: props.showEmpty,
    textOnly: props.textOnly
  };
  trfq.logEvent(eid, 'custom', customData);
};

export const isCartIconExperimentOn = async (props = {}) => {
  const host = getHost(window.location.hostname);
  const url = `https://cart.${host}/checkoutapi/v1/carticon/experiment`;
  const options = { method: 'GET', credentials: 'include' };
  options.headers = {
    'content-type': 'application/json',
    'X-App-Key': props.appKey
  };
  try {
    const res = await fetch(url, options);
    if (!res.ok) {
      logCustomEvent(`gce.cart.icon-loader.experiment-error-${res.status}.impression`, props);
      return false;
    }
    return await res.json();
  } catch (error) {
    trfq.logEvent('gce.cart.icon-loader.experiment-error.impression', 'custom', { error: error?.stack || error?.error?.stack });
    return false;
  }
};
