function logEvent(eid, type, properties) {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: 'add_event',
    version: 'v1',
    data: { eid, type, properties }
  });
}

function logImpression(eid) {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: 'add_impression',
    version: 'v1',
    data: { eid }
  });
}

function logPageReq(path) {
  global._expDataLayer = global._expDataLayer || [];
  global._expDataLayer.push({
    schema: 'add_page_request',
    version: 'v1',
    data: { virtual_path: path }
  });
}

export default {
  logPageReq,
  logImpression,
  logEvent
};
