import { css } from '@linaria/core';

export const classes = {
  default: css`
    border-width: 1px;
    border-style: solid;
    border-radius: 4px
  `,
  stack: css`
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-radius: 0px;

    &:first-of-type {
      border-top-width: 1px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-of-type {
      border-bottom-width 1px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `
};

export default (props = {}) => ({
  [classes.default]: !props.stack,
  [classes.stack]: props.stack,
  'bd-product': !props.bd && !props.border
});
