import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { initPx, loadAssets, prefetchLaunch } from './utils';

const Checkout = (props) => {
  const { id, loadingSpinner, checkoutName = 'ExpressCart', prefetchLaunch: launch } = props;
  const [prefetchId, setPrefetchId] = useState(null);
  const [isCheckoutReady, setIsCheckoutReady] = useState(window.px?.[checkoutName]?.isCheckoutReady);

  let containerId = 'checkout-cart';
  if (id) containerId = `${containerId}-${id}`;

  useEffect(() => {
    initPx({ containerId, ...props });

    const intervalId = setInterval(() => {
      if (!window.px) return;

      if (window.px[checkoutName]?.isCheckoutReady && !isCheckoutReady) {
        setIsCheckoutReady(true);
        clearInterval(intervalId);
      }
    }, 200);

    // opt-in by default
    const prefetch = launch === true ? prefetchLaunch(props) : null;
    if (prefetch) {
      setPrefetchId(prefetch);
    }

    loadAssets(props, intervalId);

    return () => {
      if (window.px) {
        window.px.isLoadingAssets = false;
      }

      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (window.px?.[checkoutName]?.loadCheckout) {
      window.px[checkoutName].loadCheckout(containerId, { ...props, prefetchId });
    }
  }, [isCheckoutReady, checkoutName]);

  return <div id={ containerId }>
    {loadingSpinner}
  </div>;
};

Checkout.propTypes = {
  appKey: PropTypes.string,
  checkoutName: PropTypes.string,
  id: PropTypes.string,
  loadingSpinner: PropTypes.object,
  onError: PropTypes.func,
  prefetchLaunch: PropTypes.bool
};

export default Checkout;
