import React from 'react';
import Notifications from './Notifications';
import useIsBrowserReady from '../hooks/useIsBrowserReady';

const NotificationsLoader = (props) => {
  const isBrowser = useIsBrowserReady();

  if (!isBrowser) return null;

  return (
    <div id='site-notifications-wrapper' style={{ display: 'flex' }}>
      <Notifications { ...props } />
    </div>
  );
};

export default NotificationsLoader;
