import createStyled from '../createStyled';
import createWithSkeleton from '../hoc/withSkeleton';
import spacing from '../styles/spacing';
import border from '../styles/border';
import visibility from '../styles/visibility';
import background from '../styles/background';
import sizing from '../styles/sizing';
import containerType from '../styles/containerType';
import containerName from '../styles/containerName';

const Block = createStyled('div', 'Block')(
  spacing,
  sizing,
  background,
  visibility,
  border,
  containerType
)(containerName);

export default createWithSkeleton(Block);
