import createStyled from '../createStyled';
import createWithSkeleton from '../hoc/withSkeleton';
import spacing from '../styles/spacing';
import typography from '../styles/typography';
import background from '../styles/background';
import visibility from '../styles/visibility';
import containerType from '../styles/containerType';
import containerName from '../styles/containerName';

const Text = createStyled('span', 'Text')(
  typography,
  background,
  visibility,
  spacing,
  containerType
)(containerName);

export default createWithSkeleton(Text);
