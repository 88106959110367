import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isCartIconExperimentOn, logCustomEvent } from './utils';
import { Cart as ClassicCartIcon } from '@ux/cart';
import '@ux/icon/cart/styles';
import CartIcon from './CartIcon';

const CartIconLoader = (props) => {
  const [newCartIcon, setNewCartIcon] = useState(null);
  const mountedRef = useRef(true);

  const fetchExperiment = useCallback(async () => {
    if (props.appKey) {
      isCartIconExperimentOn(props).then(res => {
        if (!mountedRef.current) return null;
        if (res === true) {
          return setNewCartIcon(true);
        }
        return setNewCartIcon(false);
      });
    } else {
      logCustomEvent('gce.cart.icon-loader.no-appkey.impression', props);
      return setNewCartIcon(false);
    }
  }, [mountedRef]);

  useEffect(() => {
    fetchExperiment();
    return () => (mountedRef.current = false); // clean up so we don't try to set state on an unmounted component
  }, [fetchExperiment]);

  useEffect(() => {
    if (newCartIcon !== null) {
      const status = newCartIcon ? 'enabled' : 'disabled';
      logCustomEvent(`gce.cart.icon-loader.${status}.impression`, props);
    }
  }, [newCartIcon]);

  let Component = () => <></>;
  if (newCartIcon !== null) {
    Component = newCartIcon ? CartIcon : ClassicCartIcon;
  }

  return (
    <div id='cart-icon-wrapper'>
      <Component id='cart-icon' { ...props } />
    </div>
  );
};

export default CartIconLoader;
