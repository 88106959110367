import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkout } from '@px/checkouts';

function CartIcon(props) {
  const { checkout, items } = props;

  useEffect(() => {
    if (checkout) {
      window.pxCartIcon?.updateCartUrl?.(checkout);
    }
  }, [checkout]);

  useEffect(() => {
    const count = parseInt(items, 10);
    if (count >= 0) {
      window.pxCartIcon?.refresh?.();
    }
  }, [items]);

  return (
    <Checkout
      checkoutName='CartIcon'
      { ...props }
    />
  );
}

CartIcon.propTypes = {
  checkout: PropTypes.string,
  items: PropTypes.number
};

export default CartIcon;
