import Loader from '@site/component-loader';
import { getBaseApiUrl } from '../utils';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const namespace = 'pxNotifications';
const defaultEntryKey = 'notifications';

const Notifications = (props) => {
  const baseUrl = getBaseApiUrl();
  const entryKey = props.entryKey || defaultEntryKey;

  const handleLoad = () => {
    try {
      window[namespace][entryKey].loadNotifications(props.id, { ...props });
    } catch { /* do nothing */ }
  };

  useEffect(() => {
    if (window[namespace]?.[entryKey]?.loadNotifications) {
      handleLoad();
    }
  }, []);

  let assetsUrl = `${baseUrl}/v1/assets/px-assets-notifications`;
  if (props.bucket) {
    assetsUrl = `${assetsUrl}/${props.bucket}`;
  }
  assetsUrl = `${assetsUrl}${window.location.search}`;

  return <Loader
    { ...props }
    url={ assetsUrl }
    entryKey={ entryKey }
    nameSpace={ namespace }
    replaceSrcMethodName='getClientFiles'
    onLoad={ handleLoad }
    loadingSpinner={ <div></div> }
  />;
};

Notifications.propTypes = {
  appKey: PropTypes.string.isRequired,
  bucket: PropTypes.string,
  entryKey: PropTypes.string,
  id: PropTypes.string
};

export default Notifications;
