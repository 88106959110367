/* eslint-disable max-statements */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getInfo } from './getInfo';

/**
 * translateToReactServerDOM will convert props to strings for ReactDOMServer.toString
 * to create a markup script element
 * @param {*} props the react props object
 * @returns {string} can be embedded into a script block for ReactDOMServer.toString
 */
export function translateToReactServerDOM(props) {
  const serializedProps = [];
  Object.entries(props).forEach(([key, value]) => {
    if (typeof value === 'string') {
      serializedProps.push(`${key}: '${value}'`);
    } else if (typeof value === 'function') {
      serializedProps.push(`${key}: function(payload) {
        if (window[props.nameSpace][props.id].${key}) {
          return window[props.nameSpace][props.id].${key}(payload);
        }
        window[props.nameSpace][props.id].delayed.${key} = { payload };
      }`);
    }
  });

  return `const props = { ${serializedProps.join(', ')} }`;
}

/**
 * translateFromReactServerDOM will iterate props and look for queued action results that
 * are available on the client already. It will also wire the static prop functions
 * to the real functions so we only need to iterate once
 * @param {*} props the React props
 * @param {*} container the object add the delegate methods to
 */
export function translateFromReactServerDOM(props, container) {
  Object.entries(props).forEach(([key, value]) => {
    if (typeof value === 'function') {
      if (container.delayed[key]) {
        value(container.delayed[key].payload);
        delete container.delayed[key];
      }
      container[key] = value;
    }
  });
}

function ServerDOMLoader(props) {
  const { id, nameSpace, onPrefetch, loadingSpinner } = props;

  useEffect(() => {
    onPrefetch?.();
    window[nameSpace] = window[nameSpace] || {};
    window[nameSpace][id] = window[nameSpace][id] || { delayed: {} };
    const container = window[nameSpace][id];
    translateFromReactServerDOM(props, container);
  }, []);

  return (
    <div id={ id }>
      {loadingSpinner}
      <script dangerouslySetInnerHTML={{ __html: `
      (function(){
        ${translateToReactServerDOM(props)}

        window[props.nameSpace] = window[props.nameSpace] || {};
        window[props.nameSpace][props.id] = window[props.nameSpace][props.id] || { delayed: {} };

        (${getInfo.toString()})(props);
      })();` }}></script>
    </div>
  );
}

export default ServerDOMLoader;

ServerDOMLoader.propTypes = {
  url: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  entryKey: PropTypes.string.isRequired,
  nameSpace: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  replaceSrcMethodName: PropTypes.string.isRequired,
  onError: PropTypes.func,
  onPrefetch: PropTypes.func,
  onAssetsLoad: PropTypes.func,
  loadingSpinner: PropTypes.func
};
