/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { styled } from '@linaria/react';

const Skeleton = styled.div`
  svg { display: block };
`;

// eslint-disable-next-line react/prop-types
const withSkeleton = (Component) => ({ skeleton, children, ...props }) => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const margin = 10;
  const radius = 3;

  const updateDimensions = () => {
    const height = ref?.current?.scrollHeight || 10;
    const width = ref?.current?.scrollWidth || 0;

    if (height !== dimensions.height || width !== dimensions.width) {
      setDimensions({ height, width });
    }
  };

  useEffect(() => {
    if (ref.current && typeof skeleton !== 'undefined') {
      updateDimensions();
      const observer = new MutationObserver(updateDimensions);
      observer.observe(ref.current, { childList: true, subtree: true });
      return () => observer.disconnect();
    }
  }, []);

  const contentLoader = (
    <Skeleton>
      <ContentLoader speed={ 2 } width={ dimensions.width } height={ dimensions.height }>
        <rect
          x={ 0 }
          y={ margin / 2 }
          rx={ radius }
          ry={ radius }
          width={ dimensions.width }
          height={ dimensions.height - margin }
        />
      </ContentLoader>
    </Skeleton>
  );

  return (
    <Component ref={ ref } { ...props }>
      {skeleton ? contentLoader : children}
    </Component>
  );
};

export default withSkeleton;
