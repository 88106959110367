
export default ((key, propValue) => {
  const obj = {};
  switch (key) {
    case 'role':
    case 'aria-label':
    case 'data-cy':
    case 'data-test':
    case 'data-testid':
      obj[key] = propValue;
      return obj;
    case 'heading':
    case 'aria-level':
      if (/^[1-6]?$/.test(propValue)) {
        return { 'role': 'heading', 'aria-level': propValue };
      }
      return null;
    default:
      return null;
  }
});
