import { css } from '@linaria/core';

export const classes = {
  'normal': css`container-type: normal;`,
  'size': css`container-type: size;`,
  'inline-size': css`container-type: inline-size;`,
  'inherit': css`container-type: inherit;`,
  'initial': css`container-type: initial;`,
  'revert': css`container-type: revert;`,
  'revert-layer': css`container-type: revert-layer;`,
  'unset': css`container-type: unset;`
};

export default (props = {}) => {
  return {
    [classes[props.containerType]]: Object.keys(classes).includes(props.containerType)
  };
};
