import { css } from '@linaria/core';

export const sizes = {
  xsmall: '-2',
  small: '-1',
  medium: '0',
  large: '3',
  xlarge: '6'
};

export const classes = {
  compact: css`
    line-height: 1.25rem;
  `,
  wrap: css`
    word-break: break-word;
    overflow-wrap: anywhere;
  `
};

export default (props = {}) => ({

  /* text-color */
  [`text-${props.color}`]: props.color,

  /* font-weight */
  'font-weight-bold': props.bold,
  'font-weight-light': props.light,
  [`font-weight-${props.weight}`]: props.weight,

  /* font-size */
  [`ms${sizes.xsmall}`]: props.xsmall,
  [`ms${sizes.small}`]: props.small,
  [`ms${sizes.medium}`]: props.medium,
  [`ms${sizes.large}`]: props.large,
  [`ms${sizes.xlarge}`]: props.xlarge,
  [`ms${sizes[props.size] || props.size}`]: props.size,

  /* text-align */
  [`text-${props.align}`]: props.align,

  /* line-height */
  [classes.compact]: props.compact,

  /* word break */
  [classes.wrap]: props.wrap
});
